import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Injectable()
export class MovieService {
    // host = 'localhost:8080/api';
    // host = 'https://movies.cumshotxx.com/api';
    host = '/api';
    domainUrl = '/domain';
    movieUrl = '/movie';
    categotyUrl = '/category';
    tagUrl = '/tag';
    pornstarUrl = '/author';

    options = {
        headers: { origin_domain: 'https://pornzog.club' }
    };
    orders = [
        {
            name: 'Newest Videos', value: 'NEWEST', isActive: true, index: 0
        },
        {
            name: 'Old Videos', value: 'OLD', isActive: false, index: 1
        },
        {
            name: 'Most Viewed', value: 'MOSTVIEW', isActive: false, index: 3
        },
        {
            name: 'Top Rated', value: 'RATING', isActive: false, index: 2
        }
    ]
    constructor(
        private http: HttpClient
    ) { }
    getDomainInfo() {
        return this.http.post(`${this.host}${this.domainUrl}/info`, {},this.options).toPromise();
    }
    getDomainMoreInfo() {
        return this.http.post(`${this.host}${this.domainUrl}/more-info`, {},this.options).toPromise();
    }

    listMovie(body: any) {
        return this.http.post(`${this.host}${this.movieUrl}/query-movie-by-domain`, body,this.options).toPromise();
    }
    readMovie(code: string) {
        return this.http.get(`${this.host}${this.movieUrl}/${code}`,this.options).toPromise();
    }
    likeMovie(id: number) {
        return this.http.post(`${this.host}${this.movieUrl}/${id}/like`,{},this.options).toPromise();
    }
    readMovieInfo(id: number) {
        return this.http.get(`${this.host}${this.movieUrl}/${id}/info`,this.options).toPromise();
    }

    readCategoryByCode(code: string) {
        return this.http.get(`${this.host}${this.categotyUrl}/${code}`,this.options).toPromise();

    }
    readTagByCode(code: string) {
        return this.http.get(`${this.host}${this.tagUrl}/${code}`,this.options).toPromise();

    }
    readAllTag() {
        return this.http.post(`${this.host}${this.tagUrl}/all-tag`, {},this.options).toPromise();
    }

    readPornstar(code:string){
        return this.http.get(`${this.host}${this.pornstarUrl}/${code}`,this.options).toPromise();

    }
    listRelationMovie(body: any) {
        return this.http.post(`${this.host}${this.movieUrl}/query-relation-movie`, body,this.options).toPromise();
    }

    listCategory(body: any) {
        return this.http.post(`${this.host}${this.categotyUrl}/query`, body,this.options).toPromise();
    }

    listPornStars(body: any) {
        return this.http.post(`${this.host}${this.pornstarUrl}/query`, body,this.options).toPromise();
    }
}