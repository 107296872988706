import { NgModule,CUSTOM_ELEMENTS_SCHEMA ,NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ListVideoComponent
} from './list-video/list-video.component';
import {RouterModule} from '@angular/router';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { ListVideoDetailComponent } from './list-video-detail/list-video-detail.component';
import { CLOUDINARY_CONFIG, NgxPictureModule } from 'ngx-picture';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgxPictureModule.forRoot(CLOUDINARY_CONFIG)

    ],
    declarations: [
        ListVideoComponent,
        LeftMenuComponent,
        ListVideoDetailComponent
    ],
    exports: [
        ListVideoComponent,
        LeftMenuComponent
    ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class SharedModule { }