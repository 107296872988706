<header class="">
   <div class="top-header">
      <div class="width-wrap section-container">
         <div class="search-section">
            <div class="logo-container">
               <a class="logo" routerLink="/" data-flyout-hint-content="">
                  <img src="./assets/images/imgpsh_fullsize_anim.png" alt="Pornzog – Porno-Videos kostenlos">
                  <div class="preference-hint straight">
                     US
                  </div>
               </a>
            </div>
            <form class="search-container search-container-block xh-dropdown popup positioned" method="get">
               <input class="search-text" autocomplete="off" type="text" maxlength="120" placeholder="Suche in Videos"
                  name="q" value="" eid1="62" style="margin-left: 15px;" [(ngModel)]="keyword">
               <div class="search-submit-container">
                  <button class="search-submit" type="submit" value="" (click)="onChangeKeyword()"><i
                        class="xh-icon search"></i></button>
               </div>
               <div class="search-choose-dropdown xh-dropdown popup">
                  <a class="search-choose trigger on-click" data-scope="video" data-icon="video2"
                     data-active-counter="Suche in Videos" eid1="57">
                     <i class="xh-icon video2 cobalt"></i>
                  </a>
                  <ul class="search-menu dropdown search-category-dropdown" eid1="58">
                     <li class="search-menu-switcher with-icon">
                        <a class="item" data-icon="video2" data-active-counter="Suche in Videos" data-scope="video"
                           eid1="59">
                           <i class="xh-icon video2 cobalt"></i>Videos </a>
                     </li>
                     <li class="search-menu-switcher with-icon">
                        <a class="item" data-icon="photo" data-active-counter="Search by photos..." data-scope="photo"
                           eid1="60">
                           <i class="xh-icon photo cobalt"></i>Fotos </a>
                     </li>
                     <li class="search-menu-switcher with-icon">
                        <a class="item" data-icon="friends" data-active-counter="Search by users..." data-scope="user"
                           eid1="61">
                           <i class="xh-icon friends cobalt"></i>Benutzer </a>
                     </li>
                  </ul>
               </div>
               <ul class="search-suggestions">
               </ul>
            </form>
         </div>
      </div>
   </div>
   <nav class="top-menu-container">
      <div class="top-menu width-wrap">
         <ul class="top-menu__side-group left top-menu-collapsable">
            <li class="nav-item channels displayed-nav-item">
               <a data-statistic-name="Channels" routerLink="/" eid1="30">Home</a>
           </li>
            <li class="nav-item counter-subscription-nav displayed-nav-item">
               <a data-statistic-name="Subscriptions button" href="https://de.megaxh.com/my/subscriptions"
                  class="red-round counter-subscriptions xh-helper-hidden" eid1="13">0</a>
            </li>
            <li class="nav-item channels displayed-nav-item">
               <a data-statistic-name="Channels" routerLink="/pornstars" eid1="30">Pornstars</a>
           </li>
            <li class="nav-item displayed-nav-item" data-item="premium">
               <a data-statistic-name="Premium" href="https://pornzog.com" target="_blank" eid1="38">
                  <img height="22px" src="./assets/styles/v9f7f334fd7.faphouse-logo-crown.svg"
                     alt="Pornzog - High-quality Porn Videos">
                  <span class="fap-highlight">Porn</span>Zog
               </a>
            </li>
            <li class="nav-item displayed-nav-item" data-item="premium">
               <a data-statistic-name="Premium" href="https://vlxx.sex" target="_blank" eid1="38">
                  <img height="22px" src="./assets/styles/v9f7f334fd7.faphouse-logo-crown.svg"
                     alt="Vlxx - High-quality Porn Videos">
                  <span class="fap-highlight">Vl</span>xx
               </a>
            </li>
            <li class="nav-item displayed-nav-item" data-item="premium">
               <a data-statistic-name="Premium" href="https://pornzog.club" target="_blank" eid1="38">
                  <img height="22px" src="./assets/styles/v9f7f334fd7.faphouse-logo-crown.svg"
                     alt="Pornzog - High-quality Porn Videos">
                  <span class="fap-highlight">Porn</span>69
               </a>
            </li>
         </ul>
      </div>
   </nav>
</header>